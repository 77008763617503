<template lang='pug'>
aside.quest
  .quest__cont.cont
    h1.quest__caption.caption
      | Парковый Квест «Тайна Утерянных вкусов»
    p.quest__lid.text-big
      | Уникальный формат квеста - он полностью автоматизированный, с помощью своего мобильного телефона вы заходите
      | на специальный сайт, где получите задания для выполнения.
    h2.quest__title.title
      | Что вас ждет?
    p.quest__text-big.text-big
      | Погрузитесь в сладкое приключение, где каждый шаг наполнен загадками!
    ul.quest__list
      li.quest__item.text-big Исследуйте живописные уголки парка
      li.quest__item.text-big Решайте головоломки, ищите скрытые подсказки
      li.quest__item.text-big Объединитесь с семьей или друзьями
    h2.quest__title.title
      | Как это работает:
    ul.quest__list
      li.quest__item.text-big Собираете команду
      li.quest__item.text-big Получайте коробку со всем необходимым на кассе музея
      li.quest__item.text-big С помощью мобильного телефона заходите на специальный сайт и выполняете задания
      li.quest__item.text-big Разгадываете тайну утерянных вкусов и получаете сладкий приз
    p.quest__text-big.text-big
      | «Тайна ждёт Вас среди деревьев…»
    h2.quest__title.quest__title_price.title Стоимость и длительность квеста
    span.quest__text.headline Число участников — 5-7 человек
    span.quest__note.text * можно создать 2 команды и играть на  время
    span.quest__text.headline Стоимость — 3000&nbsp;₽ за 1 квест
    span.quest__duration Продолжительность 40-60 минут
    event-contact(:title="'Узнайте больше по телефону:'")
</template>

<script>
import '@/assets/styles/components/quest.sass'
import EventContact from '../EventContact'

export default {
  name: 'TheQuest',
  components: { EventContact }
}
</script>
