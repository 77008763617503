<template lang='pug'>
head-meta(
  title-page="Квесты – Музей «Шоколадный Выборг»",
  description=""
)
the-quest
contacts-line
</template>

<script>
import TheQuest from '../components/quest/TheQuest'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'QuestsPage',
  components: { ContactsLine, HeadMeta, TheQuest }
}
</script>
